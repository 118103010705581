<template>
  <div class="options first_page_margin">
    <div class="options_head">
      <div class="symbol_change">
        <van-icon class="icon" size="20px" name="search" @click="open_symbol_list" />
        <div class="symbol_title" v-html="target+'/'+base">
        </div>
      </div>
      <div class="symbol_quote_btn" @click="to_quote">
        <img src="@/assets/kline.png">
      </div>
    </div>

    <div class="options_content">
      <div class="left">
        <div class="left_title" style="margin-bottom: 10px;">
          <van-tabs v-model="left_active" background="rgba(0,0,0,0)" title-inactive-color="#888888"
                    :color="left_active_color" :title-active-color="left_active_color"
                    @click="leftOprChange">
            <van-tab name="buy" :title="$t('message.options_left_buy')" />
            <van-tab name="sell" :title="$t('message.options_left_sell')" />
          </van-tabs>
        </div>
        <div class="left_item_mode_block">
          <div class="title">
            {{$t('message.options_left_mode_title')}}
          </div>
          <div class="value">
            <van-dropdown-menu>
              <van-dropdown-item style="width: 100px;background-color: unset;"
              v-model="mode_option" :options="mode_options"/>
            </van-dropdown-menu>
          </div>
        </div>

        <div class="left_item_price_block">
          <div class="title">
            {{$t('message.options_left_price_title')}}
          </div>
          <div class="input_block">
            <input class="input_text"
            :placeholder="$t('message.options_left_price_placeholder')"
            v-model="order_amount">
            <div class="input_mode" v-html="base"></div>
          </div>
        </div>

        <div class="left_item_mode_block">
          <div class="title">
            {{$t('message.options_left_time_title')}}
          </div>
          <div class="value">
            <van-dropdown-menu>
              <van-dropdown-item style="width: 100px;background-color: unset;"
              v-model="time_option" :options="time_options" @change="time_change"/>
            </van-dropdown-menu>
          </div>
        </div>


        <div class="left_item_profit_block">
          <div class="title">
            {{$t('message.options_left_profit')}}
          </div>
          <div class="text" v-html="profit_text"></div>
          <div class="mode"> %</div>
        </div>

        <div class="left_item_usable_block" style="margin-top: 30px;">
          <div class="title">
            {{$t('message.options_left_usable')}}
          </div>
          <div class="text" v-html="usable_text"></div>
          <div class="mode" v-html="base"></div>
        </div>

        <van-button :class="exc_btn_class" :text="exc_btn_text"
        @click="order_btn"></van-button>

      </div>

      <div class="right">
        <div class="current_price_block" style="">
          <div class="current_price" v-html="current_price"></div>
          <div class="base" v-html="base"></div>
        </div>
        <div class="right_title_block" style="margin: 30px 0 10px 0;">
          <div class="right_title" v-html="$t('message.options_depth_price')"></div>
          <div class="right_title" v-html="$t('message.options_depth_vol')"></div>
        </div>
        <div class="depth_sell_block" style="margin-bottom: 30px;">
          <div class="line" v-for="item in depth_sell_array">
            <DepthItem :price="item.price" :vol="item.vol" type="1"></DepthItem>
          </div>
        </div>

        <div class="depth_buy_block" style="margin-top: 30px;">
          <div class="line" v-for="item in depth_buy_array">
            <DepthItem :price="item.price" :vol="item.vol" type="2"></DepthItem>
          </div>
        </div>
      </div>
    </div>

    <div class="history_title_block" style="margin-bottom: 10px;">
      <van-tabs v-model="order_list_active" background="rgba(0,0,0,0)" title-inactive-color="#888888"
                :color="order_list_color" :title-active-color="order_list_color"
                @click="orderListChange">
        <van-tab name="transaction_list" :title="$t('message.options_order_list_title1')" style="margin-bottom: 30px;">
          <div class="transaction_list_content">
            <div class="transaction_list_subtitle_block">
              <div class="transaction_list_subtitle text_center ">
              {{$t('message.options_order_list_pair')}}
              </div>
              <div class="transaction_list_subtitle text_center ">
              {{$t('message.options_order_list_dir')}}
              </div>
              <div class="transaction_list_subtitle text_center ">
              {{$t('message.options_order_list_op')}}
              </div>
              <div class="transaction_list_subtitle text_center ">
              {{$t('message.options_order_list_pp')}}
              </div>
              <div class="transaction_list_subtitle text_center ">
              {{$t('message.options_order_list_cp')}}
              </div>
              <div class="transaction_list_subtitle text_center ">
              {{$t('message.options_order_list_el')}}
              </div>
              <div class="transaction_list_subtitle text_center ">
              {{$t('message.options_order_list_cd')}}
              </div>
            </div>
            <div class="transaction_list_item" v-for="item in transaction_list">
              <div class="transaction_list_item_col text_center"
              v-html="item.target + '/' + item.base">
              </div>
              <div class="transaction_list_item_col text_center"
              v-html="$t('message.options_left_buy')" v-if="item.direct > 1" />
              <div class="transaction_list_item_col text_center"
              v-html="$t('message.options_left_sell')" v-else/>
              <div class="transaction_list_item_col text_center" v-html="item.amount"></div>
              <div class="transaction_list_item_col text_center" v-html="item.trade_price"></div>
              <div class="transaction_list_item_col text_center">-</div>
              <div class="transaction_list_item_col text_center" v-html="item.estimate"></div>
              <div class="transaction_list_item_col text_center"
              v-html="item.will_end_time > cur_time ? (item.will_end_time-cur_time) : 0">
              </div>
            </div>
            <div class="text_center" style="margin: 20px;"
            v-html="$t('message.home_market_quote_no_data')"  v-if="transaction_list.length <= 0"></div>
          </div>

        </van-tab>
        <van-tab name="closed_list" :title="$t('message.options_order_list_title2')" style="margin-bottom: 20px;">
          <div class="closed_list_subtitle page_flex page_flex_row">
            <div class="closed_list_content">
              <div class="closed_list_subtitle_block">
                <div class="closed_list_subtitle text_center ">
                {{$t('message.options_order_list_pair')}}
                </div>
                <div class="transaction_list_subtitle text_center ">
                {{$t('message.options_order_list_dir')}}
                </div>
                <div class="closed_list_subtitle text_center ">
                {{$t('message.options_order_list_op')}}
                </div>
                <div class="closed_list_subtitle text_center ">
                {{$t('message.options_order_list_pp')}}
                </div>
                <div class="closed_list_subtitle text_center ">
                {{$t('message.options_order_list_tp')}}
                </div>
                <div class="closed_list_subtitle text_center ">
                {{$t('message.options_order_list_fee')}}
                </div>
                <div class="closed_list_subtitle text_center ">
                {{$t('message.options_order_list_pl')}}
                </div>
              </div>
              <div class="closed_list_item" v-for="item in closed_list">
                <div class="closed_list_item_col text_center"
                v-html="item.target + '/' + item.base">
                </div>
                <div class="transaction_list_item_col text_center"
                v-html="$t('message.options_left_buy')" v-if="item.direct > 1" />
                <div class="transaction_list_item_col text_center"
                v-html="$t('message.options_left_sell')" v-else/>
                <div class="closed_list_item_col text_center" v-html="item.amount"></div>
                <div class="closed_list_item_col text_center" v-html="item.trade_price"></div>
                <div class="closed_list_item_col text_center" v-html="item.deal_price"></div>
                <div class="closed_list_item_col text_center" v-html="item.fee*item.amount"></div>
                <div class="closed_list_item_col text_center" v-html="item.estimate"></div>
              </div>
              <div class="text_center" style="margin: 20px;"
              v-html="$t('message.home_market_quote_no_data')" v-if="closed_list.length <= 0"></div>
            </div>
          </div>
        </van-tab>
      </van-tabs>

    </div>

    <van-popup
      v-model="symbol_list_show"
      closeable
      close-icon-position="top-left"
      position="left"
      style="height: 100vh;width: 280px;">
      <div class="symbol_list_block">
        <van-cell class="symbol_list_item" v-for="(item,index) in symbol_list"
        @click="symbol_item_click(item.target, item.base)" :key="index">

          <quote-item :img_path="item.img_path" :symbol_target="item.target"
                      :symbol_base="item.base" :price="item.price" :vol="item.vol"
                      :change="item.percent">

          </quote-item>

        </van-cell>
      </div>

    </van-popup>

    <van-popup
      v-model="options_progress_show">
      <div class="options_progress_block">
        <van-circle v-model="current_rate" rate="100" :speed="progress_speed"
        :text="progress_text" />
      </div>

    </van-popup>
  </div>

</template>

<script>
import DepthItem from "@/components/DepthItem";
import QuoteItem from "@/components/QuoteItem";
import { depthData } from '@/api/quote';
import { getHomeQuote } from '@/api/home';
import { optionsPackages,userWallet,optionsOrderList,optionsOrderInfo,orderOptions } from '@/api/trade';

export default {
  name: 'Options',

  components: {
    DepthItem,
    QuoteItem
  },

  created () {
    const symbolInfo = this.$store.getters['symbol/symbolInfo']
    // console.log("symbolInfo:",symbolInfo);
    this.target = symbolInfo.target;
    this.base = symbolInfo.base;
    if (symbolInfo.direct > 1){
      this.leftOprChange('buy');
    }
    else {
      this.leftOprChange('sell');
    }

    let that = this;

    this.depth_timer_handle = setInterval(function (){
      that.get_depth_data(that.target,that.base);
      that.order_list_tran();
      that.order_list_closed();
    },10000);
    console.log('options created.....'+this.depth_timer_handle);
  },
  data () {
    return {
      target:'BTC',
      base:'USDT',
      current_price:0,
      left_active:"buy",
      left_active_color:'#22aa66',
      options_packages:[],
      mode_options:[
        { text: this.$t('message.options_left_mode_opt1'), value: 0 },
      ],
      cur_time:0,
      time_options:[],
      mode_option:0,
      time_option:0,
      order_amount:0,
      usable_text:0,
      exc_btn_class:'buy_btn',
      exc_btn_text: this.$t('message.options_left_buy') + ' USDT',
      depth_buy_array:[],
      depth_sell_array:[],
      order_list_active:'transaction_list',
      order_list_color:'#22aaff',
      depth_timer_handle:0,
      profit_text:'',
      transaction_list:[],
      closed_list:[],
      symbol_list_show:false,
      symbol_list:[],
      options_progress_show:false,
      current_rate:0,
      total_progress:60,
      progress_speed:(100/60),
      order_id:0,
      check_order_info:false,
    }
  },
  methods: {
    leftOprChange(index, title) {
      this.left_active = index;
      if (index === 'buy'){
        this.left_active_color = '#22aa66';
        this.exc_btn_class = 'buy_btn';
        this.exc_btn_text = this.$t('message.options_left_buy') + ' ' + this.base;
      }
      else {
        this.left_active_color = '#ff2222';
        this.exc_btn_class = 'sell_btn';
        this.exc_btn_text = this.$t('message.options_left_sell') + ' ' + this.target;
      }
    },
    orderListChange(index, title){

    },
    open_symbol_list(){
      this.symbol_list_show = true;
      this.refresh_list_quote();
    },
    symbol_item_click(target,base){
      this.symbol_list_show = false;
      this.target = target;
      this.base = base;
      this.leftOprChange(this.left_active,'');
      //todo refresh all data
    },
    to_quote(){
      this.$router.push({
        path: '/quote',
        query: {
          target: this.target,
          base: this.base,
          pos:2,
        }
      })
    },
    async get_depth_data($target,$base){
      const depth_data = await depthData($target,$base)
      console.log(depth_data);
      if(depth_data.code == 0){
        this.current_price = depth_data.curren_price;
        this.depth_buy_array = depth_data.depth_buy_array;
        this.depth_sell_array = depth_data.depth_sell_array;
      }
    },
    async refresh_list_quote(){
      const home_quote = await getHomeQuote()
      console.log(home_quote);
      if(home_quote.code == 0){
        this.symbol_list = home_quote.list_quote;
      }
    },
    async order_list_tran(){
      const res = await optionsOrderList(0);
      console.log(res);
      if(res.code == 0){
        this.transaction_list = res.options_list;
        this.cur_time = res.cur_time;
      }
    },
    async order_list_closed(){
      const res = await optionsOrderList(-1);
      console.log(res);
      if(res.code == 0){
        this.closed_list = res.options_list;
        this.cur_time = res.cur_time;
      }
    },
    async order_info(order_id){
      this.check_order_info = false;
      this.options_progress_show = false;
      const res = await optionsOrderInfo(order_id);
      console.log(res);
      if(res.code == 0 && res.options_list){
        let status = res.options_list.status;
        if(status >=2 || status <=3){

          if(2 == status){
            console.log('user lost.......');
            this.$toast(this.$t('message.options_lost'));
          }
          else if(3 == status){
            console.log('user won.......');
            this.$toast(this.$t('message.options_won'));
          }
          else{
            console.log('else.....'+status);
            this.$toast(this.$t('message.system_busy'));
          }

        }
      }
      else{
        console.log('else.....'+status);
        this.$toast(this.$t('message.system_busy'));
      }
    },
    async get_options_packages(){
      const res = await optionsPackages();
      if(res.code == 0){
        this.options_packages = res.options_list;
        for(let i = 0;i < res.options_list.length;i++){
          let item = res.options_list[i];
          this.time_options.push({
            text:item.period,
            value:i
          })
        }
        this.time_option = 0;
        this.profit_text = this.options_packages[0].rate * 100;
      }
    },
    time_change(value){
      console.log('time_change:',value);
      this.time_option = value;
      this.profit_text = this.options_packages[value].rate * 100;
    },
    async user_options_wallet(){
      const res = await userWallet(3);
      if(res.code == 0){
        this.usable_text = parseFloat(res.wallet_list[0].usable).toFixed(2);
      }
    },
    async order_Options(){
      let package_id = this.options_packages[this.time_option].id;
      let target = this.target;
      let dir = 2;
      if(this.left_active == 'buy'){
        dir = 1;
      }
      const res = await orderOptions(package_id,target,dir,this.order_amount);

      if(res.code == 0){
        this.check_order_info = true;
        this.current_rate = 0;
        this.total_progress = this.options_packages[this.time_option].period;
        this.progress_speed = 100 / this.total_progress;
        this.options_progress_show = true;
        this.order_id = res.order_id;
        console.log('res.order_id:',this.order_id);
        console.log('total_progress:',this.total_progress);
        console.log('current_rate:',this.current_rate);
        console.log('progress_speed:',this.progress_speed);
      }
      else{
        this.$toast(res.info);
      }
    },
    order_btn(){
      let min = parseFloat(this.options_packages[this.time_option].min);
      if(this.order_amount < min){
        this.$toast(this.$t('message.options_amount_low') + min);
        return;
      }
      this.order_Options();
    }
  },
  mounted() {
    console.log('options mounted1.....'+this.depth_timer_handle);
    this.get_depth_data(this.target,this.base);
    this.get_options_packages();
    this.user_options_wallet();
    this.order_list_tran();
    this.order_list_closed();
  },
  beforeDestroy(){
    console.log('options beforeDestroy.....'+this.depth_timer_handle);
    clearInterval(this.depth_timer_handle);
  },
  computed:{
    progress_text(){
      // console.log('total_progress:',this.total_progress);
      // console.log('current_rate:',this.current_rate);
      let num_show = this.total_progress -
      (this.current_rate * (1 / this.progress_speed));
      num_show = (num_show < 0) ? 0 : num_show;
      if(num_show <= 0 && this.check_order_info){
        let that = this;
        setTimeout(function(){
          that.order_info(that.order_id);
        },2000);

      }

      return num_show.toFixed(0) + 'S';
    }
  },
  filters: {
    rounding4(value) {
      let realVal = "";
      if (!isNaN(value) && value !== "") {
        realVal = parseFloat(value).toFixed(4);
      } else {
        realVal = "--";
      }
      return realVal;
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.options {
  input{
    border: none;
    outline: none;
    background: unset;
  }
}

.options_head{
  .page_flex();
  .page_flex_row();

  padding: 10px;

  .symbol_change{
    .page_flex();
    .page_flex_row();
    .page_flex_grow1();

    .icon{
      padding-top: 10px;
      color: @positive_color;
    }

    .symbol_title{
      padding-left: 10px;
      line-height: 40px;
    }
  }

  .symbol_quote_btn{
    text-align: right;
    img{
      width: 40px;
    }
  }
}

.options_content{
  .page_flex();
  .page_flex_row();

  .left{
    width: 50%;

    padding: 0 20px;

    .left_title{

    }
  }

  .right{
    width: 50%;

    padding: 10px;

    .current_price_block{
      .page_flex();
      .page_flex_row();

      .current_price{
        color: @negative_color;
        font-size: 18px;
        line-height: 21px;
      }

      .base{
        line-height: 21px;
        padding-left: 10px;
        font-size: 14px;
        color: #888888;
      }
    }

    .right_title_block{
      .page_flex();
      .page_flex_row();
      .text_center();
      font-size: 14px;
      color: #888888;

      .right_title{
        width: 50%;
      }
    }

  }
}

.left_item_mode_block{
  padding: 5px 0 5px 0;
  .title{
    color: @options_subtitle_color;
  }
}

.left_item_number_block,
.left_item_price_block{
  padding: 15px 0 15px 0;
  .title{
    color: @options_subtitle_color;
  }

  .input_block{
    .page_flex();
    .page_flex_row();

    .input_text{
      width: calc(100% - 29px) ;
      padding: 5px 0;
      color: white;
      border-bottom: 1px solid @options_subtitle_color;
    }

    .input_mode{
      color: #888888;
      font-size: 14px;
      padding: 5px;
      text-align: right;
    }
  }

  .input_slider{
    margin-top: 25px;
    padding-right: 5px;
  }
}

.left_item_profit_block,
.left_item_usable_block{
  .page_flex();
  .page_flex_row();
  font-size: 14px;
  color: #888888;
  padding-right: 5px;

  .text{
    .page_flex_grow1();
    text-align: right;
    padding-right: 5px;
  }
}

::v-deep .van-dropdown-menu__bar{
  background-color: unset;
  width: 100%;
}

::v-deep .van-dropdown-menu__item{
  text-align: left;
  align-items: start;
  -webkit-box-align:start;
  -webkit-box-pack:start;
  justify-content:start;
}

::v-deep .van-dropdown-menu__title{
  width: calc(100% - 29px) ;
  color: white;
  padding: 5px 0;
  border-bottom: 1px solid @options_subtitle_color;
}

::v-deep .van-dropdown-menu__title::after{
  right: -10px;
}

.closed_list_content,
.transaction_list_content{
  padding: 5px 10px;
  overflow-x:auto;
  white-space: nowrap;
}

.closed_list_subtitle_block,
.transaction_list_subtitle_block{
  padding: 10px 0;
  min-width: 1000px;
  .page_flex();
  .page_flex_row();
  .text_center();
  justify-content: space-around;
  color: @home_market_quote_subtitle;

  .closed_list_subtitle,
  .transaction_list_subtitle{
    padding: 0 10px;
    flex: 1;
  }
}

.closed_list_item,
.transaction_list_item {
  padding: 5px 0;
  min-width: 1000px;
  .page_flex();
  .page_flex_row();
  .text_center();
  justify-content: space-around;

  .closed_list_item_col,
  .transaction_list_item_col{
    padding: 0 10px;
    flex: 1;
  }
}

.symbol_list_block{
  padding-top: 100px;
  background: @sub_bg;
  color: @sub_txt_color;

  .symbol_list_item{
    padding: 5px 10px;
    background: rgba(0,0,0,0);
  }
}

.options_progress_block{

  border-radius: 20px;
  color: @high_primary_txt_color;
}

::v-deep .van-popup{
  background: rgba(0,0,0,0);
  background-color: rgba(0,0,0,0);

  .van-circle__text{
    color: @high_primary_txt_color;
  }
}

</style>
